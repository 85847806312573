<template>
  <div class="card shadow-lg bg-gradient-secondary border-0 mb-0 w-75 justify-content-center p-5">
    <text-input
      @type="(val) => (password = val)"
      label="Password"
      placeholder="Enter your new password"
      is_password=true
    ></text-input>
    <button @click="set_password" class="btn btn-default">Set password</button>
  </div>
</template>

<script>
import TextInput from "../../components/TextInput";
import axios from "axios";
export default {
  name: "Set Password",
  components: {
    "text-input": TextInput,
  },
  data() {
    return {
      password: "",
    };
  },
  methods: {
    set_password: function () {
      axios
        .put(
          `${process.env.VUE_APP_BACKEND_URL}/api/set_password/${this.$route.query.tkn}`,
          {
            password: this.password,
            confirm_password: this.password,
          }
        )
        .then((res) => {
          console.log(res);
          this.$router.push("/login");
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style>
</style>